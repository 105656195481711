<template>
    <Header />
    
    <section class="banner" style="background-image: url('/assets/images/banner-1.jpg'); background-size: cover;" >
        <div class="container">
            <h3 style="color: lightgrey;">Contact Us</h3>
            <h6 style="color: #800;">Home > Contact Us</h6>
        </div>
    </section>


    <section>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.0335860489417!2d36.96765887496537!3d-1.1363954988527183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f3fc955678a67%3A0xb42cac64d8e16dea!2sSpur%20Mall!5e0!3m2!1sen!2ske!4v1691070100924!5m2!1sen!2ske"  style="border:0; width:100%; height:400px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    </section>


    <section style="padding-top: 100px;">

        <div class="container shadow-lg" style=" padding-top: 30px; padding-bottom: 30px;">

            <div class="row">

                <div class="col-md-4" style="text-align: center;">
                    <h1 style="color: #800;"> <i class="bi bi-envelope"></i> </h1>
                    <h4>info@wnbconcepts.com</h4>

                </div>

                <div class="col-md-4" style="text-align: center;">
                    <h1 style="color: #800;"> <i class="bi bi-phone"></i> </h1>
                    <h4>0721868636 - John</h4>
                    <h4>0713148653 - Robert</h4>

                </div>

                <div class="col-md-4" style="text-align: center;">
                    <h1 style="color: #800;"> <i class="bi bi-geo-alt"></i> </h1>
                    <h4>Spur Mall,Thika Road</h4>

                </div>

            </div>

        </div>

    </section>


        
    <Footer />
    
    
    </template>
    
    <script>
    import Header from './Header'
    import Footer from './Footer'
    export default{
        name : 'home',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    </script>