<template>
    <Header />
    
    <section class="banner" style="background-image: url('/assets/images/banner-1.jpg'); background-size: cover;" >
        <div class="container">
            <h3 style="color: lightgrey;">About Us</h3>
            <h6 style="color: #800;">Home > About Us</h6>
        </div>
    </section>



<section style="padding-top: 100px;">

<div class="container">

    <div class="row shadow-lg">

        <div class="col-md-6" style="background-image: url('/assets/projects/bypass/WhatsApp Image 2023-07-28 at 12.00.48.jpeg'); background-size: cover; min-height: 300px; background-position: center; ">

        </div>


        <div class="col-md-6" style="padding-top: 80px; padding-bottom: 50px; background-color: #F8F8F8;">

            <div>
                <h6 style="color: #800;">About Us</h6>
                <h1>Who We Are</h1>

            </div>

            <p>
                At WNB Concept LTD, we take pride in our commitment to excellence and our passion for building remarkable structures that stand the test of time. With years of experience in the construction industry, we have earned a reputation for delivering high-quality projects that exceed expectations.
            </p>

            <p>
                Our approach is centered on collaboration and customer satisfaction. We work closely with our clients to understand their vision and requirements, ensuring that each project is tailored to their unique needs. Our team employs the latest construction techniques and adheres to the highest industry standards to guarantee the successful delivery of every project.
            </p>


        </div>

    </div>

</div>

</section>


<section style="margin-top: 100px;">

    <div class="container">

        <div class="row">

            <div class="col-md-6">
               <div style="background-color: #800; color:lightgrey; padding: 20px;" class="shadow-lg">
                <h3>Our Vision</h3>
                <p>To be the leading and most respected firm in providing quality designs and professional workmanship at timely basis throughout the world</p>
               </div>
            </div>

            <div class="col-md-6">
               <div style="background-color: #800; color:lightgrey; padding: 20px;" class="shadow-lg">
                <h3>Our Mission</h3>
                <p>To provide honest and excellent construction services throughout the world to our clients satisfaction</p>
               </div>
            </div>

        </div>

    </div>

</section>




<section style="margin-top: 80px; background-color: rgba(0, 0, 0, 0.8); padding-bottom: 100px; background-image: url('/assets/projects/5bedroommansion/WhatsApp Image 2023-07-28 at 11.41.54 (1).jpeg'); background-size: cover; background-blend-mode: darken; background-attachment: fixed; ">

<div class="container">

    <div style="text-align: center; padding-top: 100px; padding-bottom: 50px">
        <h6 style="color: #800;">Our Numbers</h6>
        <h1 style="color: #800;">Why Work With Us</h1>
    </div>

    <div class="row">

        <div class="col-md-4" style="padding-top: 20px;">
           <div style="text-align: center; color: #800; border: solid 2px #800; border-radius: 10px; padding: 20px; ">
            <h6> <i class="bi bi-grid"></i> </h6>
            <h3>10000+</h3>
            <h4>Projects Done</h4>
           </div>
        </div>

        <div class="col-md-4" style="padding-top: 20px;">
           <div style="text-align: center; color: #800; border: solid 2px #800; border-radius: 10px; padding: 20px; ">
            <h6> <i class="bi bi-people"></i> </h6>
            <h3>5000+</h3>
            <h4>Recommendations</h4>
           </div>
        </div>


        <div class="col-md-4" style="padding-top: 20px;">
           <div style="text-align: center; color: #800; border: solid 2px #800; border-radius: 10px; padding: 20px; ">
            <h6> <i class="bi bi-chat"></i> </h6>

            <h3>6000+</h3>
            <h4>Positive Reviews</h4>
           </div>
        </div>

    </div>

</div>

</section>


<section>

    <div class="container" style="padding-top: 50px;">

        <div>
            <h1>Our Team</h1>
            <hr style="border: solid 1px #800;">
        </div>

        <div class="row">

            <div class="col-md-4">
                <div class="shadow-lg rounded p-2">

                    <div style="background-image: url('/assets/images/team/robert.jpeg'); background-size: cover; height: 300px; width: 100%; background-position: center; border-radius: 5px; " class="team-image"></div>

                    <div style="padding-top: 10px;">
                        <h5 style="color: #800;">Eng. Robert Nyerere</h5>
                        <h6 style="color: grey;">Co-Founder : WNB Concept LTD</h6>

                    </div>

                    <div>
                        <span v-for="star in stars" class="star">
                            <i class="bi bi-star-fill"></i>
                        </span>
                    </div>

                </div>
            </div>

            <div class="col-md-4">
                <div class="shadow-lg rounded p-2">

                    <div style="background-image: url('/assets/images/team/john.png'); background-size: cover; height: 300px; width: 100%; background-position: center; border-radius: 5px; " class="team-image"></div>

                    <div style="padding-top: 10px;">
                        <h5 style="color: #800;">Eng. John Wanjohi</h5>
                        <h6 style="color: grey;">Co-Founder : WNB Concept LTD</h6>

                    </div>

                    <div>
                        <span v-for="star in stars" class="star">
                            <i class="bi bi-star-fill"></i>
                        </span>
                    </div>

                </div>
            </div>

        </div>


    </div>

</section>



<section style="background-color: rgba(0, 0, 0, 0.8); margin-top: 100px; background-image: url('/assets/projects/5bedroommansion/WhatsApp Image 2023-07-28 at 11.41.54 (1).jpeg'); background-blend-mode: darken; background-attachment: fixed; ">

<div class="container" style=" padding-top: 50px; padding-bottom: 100px;">

   <div style="padding-bottom: 30px;">
    <h2 style="color: lightgrey;">What Clients Say</h2>
   </div>
   
    <div class="row">

        <div class="col-md-4" style="padding-top: 20px;">

            <div style="background-color: #E1DEDE; padding: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; ">
                <h4 style="color: #800;"> <i class="bi bi-person-circle"></i> Mourice Okello</h4>
                <p>
                    We approached WNB Concept LTD for our office expansion project, and they truly exceeded our expectations. Their expertise in commercial construction was evident from the start. They not only delivered a functional and aesthetically pleasing workspace but also provided insightful suggestions that enhanced the overall design. The project was completed on time and within budget, and the communication throughout the process was exceptional. We are incredibly satisfied with their services and would choose WNB Concept LTD again for any future projects.
                </p>
            </div>

        </div>


        <div class="col-md-4" style="padding-top: 20px;">

<div style="background-color: #E1DEDE; padding: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; ">
    <h4 style="color: #800;"> <i class="bi bi-person-circle"></i> Sarah Johnson</h4>
    <p>
        We engaged WNB Concept LTD for a major renovation of our restaurant, and the transformation is simply stunning. Their team seamlessly integrated modern design elements while preserving the charm of the original structure. The project was managed efficiently, and any challenges that arose were addressed promptly. The attention to detail, quality craftsmanship, and commitment to delivering beyond our expectations make WNB Concept LTD stand out. They breathed new life into our space, and we couldn't be happier
    </p>
</div>

</div>


<div class="col-md-4" style="padding-top: 20px;">

<div style="background-color: #E1DEDE; padding: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; ">
    <h4 style="color: #800;"> <i class="bi bi-person-circle"></i> James Kariuki</h4>
    <p>
        Our experience with WNB Concept LTD on our industrial facility was excellent from start to finish. Their in-depth knowledge of industrial construction regulations and processes was evident throughout the project. They provided innovative solutions that optimized the layout and functionality of the space. The dedication to safety, meticulous planning, and their ability to adapt to our evolving needs were commendable. <br/> WNB Concept LTD truly delivered a facility that aligns with our operational requirements.
    </p>
</div>

</div>

    </div>

</div>

</section>




<section style="padding-top: 80px;">

<div class="container-fluid">

    <div class="row">

        <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
            <img src="/assets/projects/bypass/WhatsApp Image 2023-07-28 at 12.00.50.jpeg" style="width: 100%;" class="shadow-lg">
        </div>

        <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
            <img src="/assets/projects/2bedroomcottagesmachakos/WhatsApp Image 2023-07-28 at 12.10.34 (3).jpeg" style="width: 100%;" class="shadow-lg">
        </div>

        <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
            <img src="/assets/projects/3bedroombungalow/WhatsApp Image 2023-07-28 at 11.47.40 (1).jpeg" style="width: 100%;" class="shadow-lg">
        </div>

        <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
            <img src="/assets/projects/3bedroomestate/WhatsApp Image 2023-07-28 at 12.04.50 (5).jpeg" style="width: 100%;" class="shadow-lg">
        </div>
        

    </div>

</div>

</section>



        
    <Footer />
    
    
    </template>
    
    <script>
    import Header from './Header'
    import Footer from './Footer'
    export default{
        name : 'home',
        components : {Header,Footer},
        data (){
            return {
                stars : [1,2,3,4,5]
            }
        },
        created (){
        window.scrollTo(0,0)
    }
    }
    </script>