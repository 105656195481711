<template>
    <Header />
    
    <section class="banner" style="background-image: url('/assets/images/banner-1.jpg'); background-size: cover;" >
        <div class="container">
            <h3 style="color: lightgrey;">Residential Engineering</h3>
            <h6 style="color: orange;">Home > Residential Engineering</h6>
        </div>
    </section>



    <section style="background-image: url('/assets/images/plan.jpg'); background-size: cover; background-color: rgba(251, 251, 251, 0.9); background-blend-mode: lighten; ">



<div class="container" style="padding-top: 100px; padding-bottom: 100px;">

    <div>
        <H1>- Residential Engineering Projects</H1>
    </div>
    
   
    <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Airways - Mansion
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/airways/WhatsApp Image 2023-07-28 at 11.37.34 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/airways/WhatsApp Image 2023-07-28 at 11.37.34.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Gitaru - Kikuyu Mansion
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/gitaru/WhatsApp Image 2023-07-28 at 11.39.42 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/gitaru/WhatsApp Image 2023-07-28 at 11.39.42.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Katani - Mavoko Mansion
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/katani/WhatsApp Image 2023-07-28 at 11.40.23 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/katani/WhatsApp Image 2023-07-28 at 11.40.26.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>



  <div class="accordion-item">
    <h2 class="accordion-header" id="headingfour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
        5 Bedrooms Mansion
      </button>
    </h2>
    <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansion/WhatsApp Image 2023-07-28 at 11.41.51 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansion/WhatsApp Image 2023-07-28 at 11.41.55 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>





  <div class="accordion-item">
    <h2 class="accordion-header" id="headingfive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
        3 Bedrooms Mansion
      </button>
    </h2>
    <div id="collapsefive" class="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroommansion/WhatsApp Image 2023-07-28 at 11.44.39 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroommansion/WhatsApp Image 2023-07-28 at 11.44.39.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>






  <div class="accordion-item">
    <h2 class="accordion-header" id="headingsix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
        4 Bedrooms Mansion - Kisumu
      </button>
    </h2>
    <div id="collapsesix" class="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionkisumu/WhatsApp Image 2023-07-28 at 11.46.43 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionkisumu/WhatsApp Image 2023-07-28 at 11.46.45.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionkisumu/WhatsApp Image 2023-07-28 at 11.46.43 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionkisumu/WhatsApp Image 2023-07-28 at 11.46.43.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>




  <div class="accordion-item">
    <h2 class="accordion-header" id="headingseven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
        3 Bedrooms Bungalow
      </button>
    </h2>
    <div id="collapseseven" class="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroombungalow/WhatsApp Image 2023-07-28 at 11.47.40 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroombungalow/WhatsApp Image 2023-07-28 at 11.47.40.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroombungalow/WhatsApp Image 2023-07-28 at 11.47.41 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroombungalow/WhatsApp Image 2023-07-28 at 11.47.42.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>




  <div class="accordion-item">
    <h2 class="accordion-header" id="headings8">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
        4 Bedrooms Bungalow
      </button>
    </h2>
    <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroombungalow/WhatsApp Image 2023-07-28 at 11.50.57.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroombungalow/WhatsApp Image 2023-07-28 at 11.50.58 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroombungalow/WhatsApp Image 2023-07-28 at 11.50.58 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroombungalow/WhatsApp Image 2023-07-28 at 11.50.58.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>






  <div class="accordion-item">
    <h2 class="accordion-header" id="headings9">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
        5 Bedrooms Mansion 2
      </button>
    </h2>
    <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansion2/WhatsApp Image 2023-07-28 at 11.53.53 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansion2/WhatsApp Image 2023-07-28 at 11.53.53 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansion2/WhatsApp Image 2023-07-28 at 11.53.53 (3).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansion2/WhatsApp Image 2023-07-28 at 11.53.53 (4).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>




</div>


</div>

</section>




        
    <Footer />
    
    
    </template>
    
    <script>
    import Header from './Header'
    import Footer from './Footer'
    export default{
        name : 'home',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    </script>