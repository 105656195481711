<template>
    <Header />
    
    <section class="banner" style="background-image: url('/assets/images/banner-1.jpg'); background-size: cover;" >
        <div class="container">
            <h3 style="color: lightgrey;"> Ongoing Projects</h3>
            <h6 style="color: #800;">Home > Ongoing ongoing-projects</h6>
        </div>
    </section>



    <section style="background-image: url('/assets/images/plan.jpg'); background-size: cover; background-color: rgba(251, 251, 251, 0.9); background-blend-mode: lighten; ">



<div class="container" style="padding-top: 100px; padding-bottom: 100px;">

    <div>
        <H1>- Ongoing Projects</H1>
    </div>
    
   
    <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        2 Bedroom Apartment in Juja Farm
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomapartmentjujafarm/WhatsApp Image 2023-07-28 at 12.28.56 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomapartmentjujafarm/WhatsApp Image 2023-07-28 at 12.28.56.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomapartmentjujafarm/WhatsApp Image 2023-07-28 at 12.28.57 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomapartmentjujafarm/WhatsApp Image 2023-07-28 at 12.28.57 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="heading2">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
        2 Bedroom Apartment in Membley
      </button>
    </h2>
    <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomapartmentmembley/WhatsApp Image 2023-07-28 at 12.30.36.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomapartmentmembley/WhatsApp Image 2023-07-28 at 12.30.36 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomapartmentmembley/WhatsApp Image 2023-07-28 at 12.30.35.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomapartmentmembley/WhatsApp Image 2023-07-28 at 12.30.12.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="heading3">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
        Commercial Complex at Kamakis
      </button>
    </h2>
    <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/commercialcomplexkamakis/WhatsApp Image 2023-07-28 at 12.31.38 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/commercialcomplexkamakis/WhatsApp Image 2023-07-28 at 12.31.38.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/commercialcomplexkamakis/WhatsApp Image 2023-07-28 at 12.31.39 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/commercialcomplexkamakis/WhatsApp Image 2023-07-28 at 12.31.39.jpeg" style="width: 100%; border-radius: 10px;"> </div>
         
        </div>
      </div>
    </div>
  </div>





  <div class="accordion-item">
    <h2 class="accordion-header" id="heading4">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
        4 Bedroom Mansionette at Kikuyu
      </button>
    </h2>
    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommassionatekikuyu/WhatsApp Image 2023-07-28 at 12.32.22.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommassionatekikuyu/WhatsApp Image 2023-07-28 at 12.32.23 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommassionatekikuyu/WhatsApp Image 2023-07-28 at 12.32.23.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommassionatekikuyu/WhatsApp Image 2023-07-28 at 12.32.24 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>






  <div class="accordion-item">
    <h2 class="accordion-header" id="heading5">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse4">
        4 Bedroom Mansion in Kibwezi
      </button>
    </h2>
    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionkibwezi/WhatsApp Image 2023-07-28 at 12.33.32.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionkibwezi/WhatsApp Image 2023-07-28 at 12.33.33.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionkibwezi/WhatsApp Image 2023-07-28 at 12.33.34 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionkibwezi/WhatsApp Image 2023-07-28 at 12.33.34.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>





  <div class="accordion-item">
    <h2 class="accordion-header" id="heading6">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
        4 Bedroom Mansion in Ruiru
      </button>
    </h2>
    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionruiru/WhatsApp Image 2023-07-28 at 12.35.15 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionruiru/WhatsApp Image 2023-07-28 at 12.35.15.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionruiru/WhatsApp Image 2023-07-28 at 12.35.16 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroommansionruiru/WhatsApp Image 2023-07-28 at 12.35.16.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>





  <div class="accordion-item">
    <h2 class="accordion-header" id="heading7">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
        Commercial Complex in Limuru
      </button>
    </h2>
    <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/commercialcomplexlimuru/WhatsApp Image 2023-07-28 at 12.36.06 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/commercialcomplexlimuru/WhatsApp Image 2023-07-28 at 12.36.06.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/commercialcomplexlimuru/WhatsApp Image 2023-07-28 at 12.36.07.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/commercialcomplexlimuru/WhatsApp Image 2023-07-28 at 12.36.08.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>




  <div class="accordion-item">
    <h2 class="accordion-header" id="heading8">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
        Church in Gachie
      </button>
    </h2>
    <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/churchgachie/WhatsApp Image 2023-07-28 at 12.37.02.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/churchgachie/WhatsApp Image 2023-07-28 at 12.37.04.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/churchgachie/WhatsApp Image 2023-07-28 at 12.37.05 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/churchgachie/WhatsApp Image 2023-07-28 at 12.37.05.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>




  <div class="accordion-item">
    <h2 class="accordion-header" id="heading9">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
        Renovation Works in Karen
      </button>
    </h2>
    <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/renovationworkskaren/WhatsApp Image 2023-07-28 at 12.38.19.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/renovationworkskaren/WhatsApp Image 2023-07-28 at 12.38.20.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/renovationworkskaren/WhatsApp Image 2023-07-28 at 12.38.21.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/renovationworkskaren/WhatsApp Image 2023-07-28 at 12.38.22 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>

          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/renovationworkskaren/WhatsApp Image 2023-07-28 at 12.38.22.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/renovationworkskaren/WhatsApp Image 2023-07-28 at 12.41.47.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/renovationworkskaren/WhatsApp Image 2023-07-28 at 12.41.46.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/renovationworkskaren/WhatsApp Image 2023-07-28 at 12.41.45 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>

</div>


</div>

</section>




        
    <Footer />
    
    
    </template>
    
    <script>
    import Header from './Header'
    import Footer from './Footer'
    export default{
        name : 'home',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    </script>