<template>
    <Header />
    
    <section class="banner" style="background-image: url('/assets/images/banner-1.jpg'); background-size: cover;" >
        <div class="container">
            <h3 style="color: lightgrey;">Architectural Engineering</h3>
            <h6 style="color: #800;">Home > Architectural Engineering</h6>
        </div>
    </section>



    <section style="background-image: url('/assets/images/plan.jpg'); background-size: cover; background-color: rgba(251, 251, 251, 0.9); background-blend-mode: lighten; ">



<div class="container" style="padding-top: 100px; padding-bottom: 100px;">

    <div>
        <H1>- Architectural Engineering Services</H1>
    </div>
    
    <div class="row">

        <div class="col-md-4">
        
            <div class="category-design" style="text-align: center; background-image: url('/assets/projects/kibwezi/WhatsApp Image 2023-07-28 at 11.57.34.jpeg'); background-size: cover; padding-top: 100px; padding-bottom: 100px; background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken; border-radius: 5px; ">
                             
            <router-link class="btn btn-lg category" to="/residential">
                Residential
            </router-link>
            </div>

        </div>

        <div class="col-md-4">
        
        <div class="category-design" style="text-align: center; background-image: url('/assets/projects/masii/WhatsApp Image 2023-07-28 at 12.08.25.jpeg'); background-size: cover; padding-top: 100px; padding-bottom: 100px; background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken; border-radius: 5px; ">
                         
        <router-link class="btn btn-lg category" to="/architectural-commercial">
            Commercial
        </router-link>
        </div>

    </div>


    <div class="col-md-4">
        
        <div class="category-design" style="text-align: center; background-image: url('/assets/projects/kahawa/WhatsApp Image 2023-07-28 at 11.56.21.jpeg'); background-size: cover; padding-top: 100px; padding-bottom: 100px; background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken; border-radius: 5px; ">
                         
        <router-link class="btn btn-lg category" to="">
            Institution
        </router-link>
        </div>

    </div>


    

    </div>


</div>

</section>




        
    <Footer />
    
    
    </template>
    
    <script>
    import Header from './Header'
    import Footer from './Footer'
    export default{
        name : 'home',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    </script>