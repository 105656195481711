import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home'
import About from '../components/About'
import Contact from '../components/Contact'
import Architectural from '../components/Architectural'
import Residential from '../components/Residential'
import ArchitecturalCommercial from '../components/ArchitecturalCommercial'
import Structural from '../components/Structural'
import Construction from '../components/Construction'
import OngoingProjects from '../components/OngoingProjects'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/about',
    name: 'about',
    component: About
  },


  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },

  {
    path: '/architectural',
    name: 'architectural',
    component: Architectural
  },

  {
    path: '/residential',
    name: 'residential',
    component: Residential
  },

  {
    path: '/architectural-commercial',
    name: 'architecturalcommercial',
    component: ArchitecturalCommercial
  },

  {
    path: '/structural',
    name: 'structural',
    component: Structural
  },

  {
    path: '/construction',
    name: 'construction',
    component: Construction
  },

  {
    path: '/ongoing-projects',
    name: 'ongoingprojects',
    component: OngoingProjects
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
