<template>
    <Header />
    
    <section class="banner" style="background-image: url('/assets/images/banner-1.jpg'); background-size: cover;" >
        <div class="container">
            <h3 style="color: lightgrey;"> Structural Engineering</h3>
            <h6 style="color: #800;">Home > Structural Engineering</h6>
        </div>
    </section>



    <section style="background-image: url('/assets/images/plan.jpg'); background-size: cover; background-color: rgba(251, 251, 251, 0.9); background-blend-mode: lighten; ">



<div class="container" style="padding-top: 100px; padding-bottom: 100px;">

    <div>
        <H1>- Structural Engineering Projects</H1>
    </div>
    
   
    <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Structural Engineering Project
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.12.13.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.12.14.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.15.59.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.00 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>

          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.00.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.01 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.01.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.04 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>

          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.04.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.05 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.05.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.06 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>

          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.18.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.18 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.18 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/structural/WhatsApp Image 2023-07-28 at 12.16.17.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>

  






</div>


</div>

</section>




        
    <Footer />
    
    
    </template>
    
    <script>
    import Header from './Header'
    import Footer from './Footer'
    export default{
        name : 'home',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    </script>