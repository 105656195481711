<template>
    <Header />
    
    <section class="banner" style="background-image: url('/assets/images/banner-1.jpg'); background-size: cover;" >
        <div class="container">
            <h3 style="color: lightgrey;"> Construction Engineering</h3>
            <h6 style="color: #800;">Home > Construction Engineering</h6>
        </div>
    </section>



    <section style="background-image: url('/assets/images/plan.jpg'); background-size: cover; background-color: rgba(251, 251, 251, 0.9); background-blend-mode: lighten; ">



<div class="container" style="padding-top: 100px; padding-bottom: 100px;">

    <div>
        <H1>- Construction Engineering Projects</H1>
    </div>
    
   
    <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        5 Bedroom Mansion in Kiambu
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansionkiambu/WhatsApp Image 2023-07-28 at 12.19.08 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansionkiambu/WhatsApp Image 2023-07-28 at 12.19.08.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansionkiambu/WhatsApp Image 2023-07-28 at 12.19.09 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommansionkiambu/WhatsApp Image 2023-07-28 at 12.19.09.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="heading2">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
        5 Bedroom in Mombasa
      </button>
    </h2>
    <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommombasa/WhatsApp Image 2023-07-28 at 12.20.25 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommombasa/WhatsApp Image 2023-07-28 at 12.20.25 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommombasa/WhatsApp Image 2023-07-28 at 12.20.25 (3).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroommombasa/WhatsApp Image 2023-07-28 at 12.20.25 (4).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="heading3">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
        Restaurant Renovation in Nakuru
      </button>
    </h2>
    <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/restaurantrenovationnakuru/WhatsApp Image 2023-07-28 at 12.21.23.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/restaurantrenovationnakuru/WhatsApp Image 2023-07-28 at 12.21.24 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/restaurantrenovationnakuru/WhatsApp Image 2023-07-28 at 12.21.24.jpeg" style="width: 100%; border-radius: 10px;"> </div>
         
        </div>
      </div>
    </div>
  </div>





  <div class="accordion-item">
    <h2 class="accordion-header" id="heading4">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
        4 Bedroom in Ewaso kedong
      </button>
    </h2>
    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kedong/WhatsApp Image 2023-07-28 at 12.22.43 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kedong/WhatsApp Image 2023-07-28 at 12.22.43 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kedong/WhatsApp Image 2023-07-28 at 12.22.43 (3).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kedong/WhatsApp Image 2023-07-28 at 12.22.43 (4).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>






  <div class="accordion-item">
    <h2 class="accordion-header" id="heading5">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse4">
        4 Bedroom in Ruiru
      </button>
    </h2>
    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroomruiru/WhatsApp Image 2023-07-28 at 12.23.59 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroomruiru/WhatsApp Image 2023-07-28 at 12.23.59.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroomruiru/WhatsApp Image 2023-07-28 at 12.24.23 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/4bedroomruiru/WhatsApp Image 2023-07-28 at 12.24.23.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>





  <div class="accordion-item">
    <h2 class="accordion-header" id="heading6">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
        5 Bedroom in Ruai
      </button>
    </h2>
    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroomruai/WhatsApp Image 2023-07-28 at 12.26.48 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroomruai/WhatsApp Image 2023-07-28 at 12.26.48 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroomruai/WhatsApp Image 2023-07-28 at 12.26.48.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/5bedroomruai/WhatsApp Image 2023-07-28 at 12.26.49 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>


</div>


</div>

</section>




        
    <Footer />
    
    
    </template>
    
    <script>
    import Header from './Header'
    import Footer from './Footer'
    export default{
        name : 'home',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    </script>