<template>
    <Header />
    
    <section class="banner" style="background-image: url('/assets/images/banner-1.jpg'); background-size: cover;" >
        <div class="container">
            <h3 style="color: lightgrey;"> Architectural Commercial Engineering</h3>
            <h6 style="color: orange;">Home > Architectural Commercial Engineering</h6>
        </div>
    </section>



    <section style="background-image: url('/assets/images/plan.jpg'); background-size: cover; background-color: rgba(251, 251, 251, 0.9); background-blend-mode: lighten; ">



<div class="container" style="padding-top: 100px; padding-bottom: 100px;">

    <div>
        <H1>- Architectural Commercial Engineering Projects</H1>
    </div>
    
   
    <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Kahawa West Flat
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kahawa/WhatsApp Image 2023-07-28 at 11.56.21.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kahawa/WhatsApp Image 2023-07-28 at 11.56.22 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kahawa/WhatsApp Image 2023-07-28 at 11.56.22.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kahawa/WhatsApp Image 2023-07-28 at 11.56.23 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="heading2">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
        Juja Farm Project
      </button>
    </h2>
    <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/juja/WhatsApp Image 2023-07-28 at 11.57.09 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/juja/WhatsApp Image 2023-07-28 at 11.57.09.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/juja/WhatsApp Image 2023-07-28 at 11.57.10 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/juja/WhatsApp Image 2023-07-28 at 11.57.10 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="heading3">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
        Kibwezi Apartment
      </button>
    </h2>
    <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kibwezi/WhatsApp Image 2023-07-28 at 11.57.34 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kibwezi/WhatsApp Image 2023-07-28 at 11.57.34.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kibwezi/WhatsApp Image 2023-07-28 at 11.57.35 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kibwezi/WhatsApp Image 2023-07-28 at 11.57.35 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>





  <div class="accordion-item">
    <h2 class="accordion-header" id="heading4">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
        Kamakis Shopping Centre
      </button>
    </h2>
    <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kamakis/WhatsApp Image 2023-07-28 at 11.58.15 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kamakis/WhatsApp Image 2023-07-28 at 11.58.15.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kamakis/WhatsApp Image 2023-07-28 at 11.58.16 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kamakis/WhatsApp Image 2023-07-28 at 11.58.16 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>






  <div class="accordion-item">
    <h2 class="accordion-header" id="heading5">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse4">
        Eastern Bypass Office Complex
      </button>
    </h2>
    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/bypass/WhatsApp Image 2023-07-28 at 12.00.48.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/bypass/WhatsApp Image 2023-07-28 at 12.00.49 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/bypass/WhatsApp Image 2023-07-28 at 12.00.49 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/bypass/WhatsApp Image 2023-07-28 at 12.00.49 (3).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>





  <div class="accordion-item">
    <h2 class="accordion-header" id="heading6">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
        2 Bedrooms Estate
      </button>
    </h2>
    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomestate/WhatsApp Image 2023-07-28 at 12.02.45.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomestate/WhatsApp Image 2023-07-28 at 12.02.46 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomestate/WhatsApp Image 2023-07-28 at 12.02.46 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomestate/WhatsApp Image 2023-07-28 at 12.02.46.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>




  <div class="accordion-item">
    <h2 class="accordion-header" id="heading7">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
        3 Bedrooms Estate
      </button>
    </h2>
    <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroomestate/WhatsApp Image 2023-07-28 at 12.04.50 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroomestate/WhatsApp Image 2023-07-28 at 12.04.50 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroomestate/WhatsApp Image 2023-07-28 at 12.04.50 (3).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/3bedroomestate/WhatsApp Image 2023-07-28 at 12.04.50 (4).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="heading8">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
        Kitengela 4 Bedroom Estate
      </button>
    </h2>
    <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kitengela/WhatsApp Image 2023-07-28 at 12.06.11 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kitengela/WhatsApp Image 2023-07-28 at 12.06.11 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kitengela/WhatsApp Image 2023-07-28 at 12.06.11 (3).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/kitengela/WhatsApp Image 2023-07-28 at 12.06.11 (4).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>




  <div class="accordion-item">
    <h2 class="accordion-header" id="heading9">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
      Masii Apartment
      </button>
    </h2>
    <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/masii/WhatsApp Image 2023-07-28 at 12.08.23.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/masii/WhatsApp Image 2023-07-28 at 12.08.24 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/masii/WhatsApp Image 2023-07-28 at 12.08.24.jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/masii/WhatsApp Image 2023-07-28 at 12.08.25.jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="heading10">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
      2 Bedroom Cottages in Machakos
      </button>
    </h2>
    <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomcottagesmachakos/WhatsApp Image 2023-07-28 at 12.10.34 (1).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomcottagesmachakos/WhatsApp Image 2023-07-28 at 12.10.34 (2).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomcottagesmachakos/WhatsApp Image 2023-07-28 at 12.10.34 (3).jpeg" style="width: 100%; border-radius: 10px;"> </div>
          <div class="col-md-3" style="padding-top: 10px;"> <img src="/assets/projects/2bedroomcottagesmachakos/WhatsApp Image 2023-07-28 at 12.10.34 (4).jpeg" style="width: 100%; border-radius: 10px;"> </div>
        </div>
      </div>
    </div>
  </div>






</div>


</div>

</section>




        
    <Footer />
    
    
    </template>
    
    <script>
    import Header from './Header'
    import Footer from './Footer'
    export default{
        name : 'home',
        components : {Header,Footer},
        created (){
        window.scrollTo(0,0)
    }
    }
    </script>