<template>

<section style="margin-top: 100px; background-color: #1a1a1a; padding-top: 50px;">

<div class="container" style="padding-bottom: 30px;">

    <div class="row">

        <div class="col-md-3">
            <img src="/assets/images/logo-2.png" style="height:70px;">
            <p style="color: lightgrey;">At WNB Concept LTD, we take pride in our commitment to excellence and our passion for building remarkable structures that stand the test of time.</p>
        </div>

        <div class="col-md-3">
            <h6 style="color:grey;">Quick links</h6>
            <router-link to="/" class="btn footer-link">Home</router-link><br/>
            <router-link to="/about" class="btn footer-link">About Us</router-link> <br/>
            <router-link to="/contact" class="btn footer-link">Contact Us</router-link>
            
        </div>

        <div class="col-md-3">
            <h6 style="color:grey;">Our Services</h6>
            <router-link to="/architectural" class="btn footer-link">Architectural</router-link><br/>
            <router-link to="/structural" class="btn footer-link">Structural</router-link> <br/>
            <router-link to="/construction" class="btn footer-link">Construction Us</router-link>
            <router-link to="/ongoing-projects" class="btn footer-link">Ongoing Projects</router-link>
        </div>

        <div class="col-md-3">
            <h6 style="color:grey;">Contact Us</h6>
            <a href="tel:0721868636" class="btn footer-link"> <i class="bi bi-phone"></i> 0721868636 - John</a>
            <a href="tel:0713148653" class="btn footer-link"> <i class="bi bi-phone"></i> 0713148653 - Robert</a>
            <a href="mailto:info@wnbconcept.com" class="btn footer-link"> <i class="bi bi-envelope"></i> info@wnbconcept.com</a>
            <a href="#" class="btn footer-link"> <i class="bi bi-geo-alt"></i> Spur Mall,Thika Road</a>

            <br />

            <a href="https://www.facebook.com/profile.php?id=100063674185746&mibextid=ZbWKwL" target="_blank" class="btn footer-link"> <i class="bi bi-facebook"></i>  </a> 
            <a href="" class="btn footer-link"> <i class="bi bi-twitter"></i>  </a> 
            <a href="" class="btn footer-link"> <i class="bi bi-instagram"></i> </a> 
        </div>

      

    </div>

</div>


<div style="color: grey; text-align: center; background-color: #000; padding: 20px;">
    <p>WNB Concept LTD.2023</p>
</div>

</section>

<div style="position: fixed; right: 0; bottom: 0; z-index: 700;">
    <a href="https://wa.me/254721868636" target="_blank" class="btn text-success btn-lg" style="font-size: 50px;"> <i class="bi bi-whatsapp"></i> </a>
</div>

</template>