<template>
<Header />

<section>
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" style="background-image: url('/assets/projects/masii/WhatsApp Image 2023-07-28 at 12.08.23.jpeg'); background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken;">
     
        <div class="container">

<div class="row" style="padding-top: 200px; padding-bottom: 100px;">

    <div class="col-md-12" style="text-align: center;">
        <h4 style="color: lightgrey;">Welcome</h4>
        <h1 style="color: lightgrey; font-size: 5vw; font-weight: none;">WNB Concept LTD</h1>
        <h4 style="color: lightgrey;">Crafting Excellence in Construction.</h4>

        <div>
            <router-link to="/about" class="btn btn-2" style="margin-right: 15px;">Learn More</router-link>

            <router-link to="/contact" class="btn btn-1">Contact Us</router-link>
        </div>

    </div>

</div>

</div> 


    </div>


    <div class="carousel-item" style="background-image: url('/assets/projects/bypass/WhatsApp Image 2023-07-28 at 12.00.50.jpeg'); background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken;">
        <div class="container">

<div class="row" style="padding-top: 200px; padding-bottom: 100px;">

    <div class="col-md-12" style="text-align: center;">
        
        <h1 style="color: lightgrey; font-size: 5vw; font-weight: none;">Residential Engineering <br/> Projects</h1>
        <h4 style="color: lightgrey;">Building Dreams, One Brick at a Time.</h4>

        <div>
            <router-link to="/about" class="btn btn-2" style="margin-right: 15px;">Learn More</router-link>

            <router-link to="/contact" class="btn btn-1">Contact Us</router-link>
        </div>

    </div>

</div>

</div>
    </div>


    <div class="carousel-item" style="background-image: url('/assets/projects/kamakis/WhatsApp Image 2023-07-28 at 11.58.17.jpeg'); background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken;">
        <div class="container">

<div class="row" style="padding-top: 200px; padding-bottom: 100px;">

    <div class="col-md-12" style="text-align: center;">
       
        <h1 style="color: lightgrey; font-size: 5vw; font-weight: none;">Innovative Construction, Enduring Results.</h1>
        <h4 style="color: lightgrey;">Architectural Engineering</h4>

        <div>
            <router-link to="/about" class="btn btn-2" style="margin-right: 15px;">Learn More</router-link>

            <router-link to="/contact" class="btn btn-1">Contact Us</router-link>
        </div>

    </div>

</div>

</div>

    </div>


    



  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</section>





<section style="background-image: url('/assets/images/plan.jpg'); background-size: cover; background-color: rgba(251, 251, 251, 0.9); background-blend-mode: lighten; ">



    <div class="container" style="padding-top: 100px; padding-bottom: 100px;">

        <div>
            <h3 style="color: #800;">Featured</h3>
            <H1>- Our Services</H1>
        </div>
        
        <div class="row">

            <div class="col-md-6">
            
                <div class="category-design" style="text-align: center; background-image: url('/assets/projects/kamakis/WhatsApp Image 2023-07-28 at 11.58.15.jpeg'); background-size: cover; padding-top: 100px; padding-bottom: 100px; background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken; border-radius: 5px; ">
                                 
                <router-link class="btn btn-lg category" to="/structural">
                    Structural
                </router-link>
                </div>

            </div>

            <div class="col-md-6">
            
            <div class="category-design" style="text-align: center; background-image: url('/assets/projects/gitaru/WhatsApp Image 2023-07-28 at 11.39.43 (2).jpeg'); background-size: cover; padding-top: 100px; padding-bottom: 100px; background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken; border-radius: 5px; ">
                             
            <router-link class="btn btn-lg category" to="/architectural">
                Architectural
            </router-link>
            </div>

        </div>


        <div class="col-md-6">
            
            <div class="category-design" style="text-align: center; background-image: url('/assets/projects/bypass/WhatsApp Image 2023-07-28 at 12.00.48.jpeg'); background-size: cover; padding-top: 100px; padding-bottom: 100px; background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken; border-radius: 5px; ">
                             
            <router-link class="btn btn-lg category" to="/construction">
                Construction
            </router-link>
            </div>

        </div>



        <div class="col-md-6">
            
            <div class="category-design" style="text-align: center; background-image: url('/assets/projects/juja/WhatsApp Image 2023-07-28 at 11.57.09 (1).jpeg'); background-size: cover; padding-top: 100px; padding-bottom: 100px; background-color: rgba(0, 0, 0, 0.5); background-blend-mode: darken; border-radius: 5px; ">
                             
            <router-link class="btn btn-lg category" to="/ongoing-projects">
                Ongoing Projects
            </router-link>
            </div>

        </div>


        

        </div>


       

    </div>

</section>



<section style="padding-top: 100px;">

    <div class="container">

        <div class="row shadow-lg">

            <div class="col-md-6" style="background-image: url('/assets/projects/2bedroomestate/WhatsApp Image 2023-07-28 at 12.02.46 (1).jpeg'); background-size: cover; min-height: 300px; background-position: center; ">

            </div>


            <div class="col-md-6" style="padding-top: 80px; padding-bottom: 50px; background-color: #F8F8F8;">

                <div>
                    <h6 style="color: #800;">About Us</h6>
                    <h1>Who We Are</h1>

                </div>

                <p>
                    At WNB Concept LTD, we take pride in our commitment to excellence and our passion for building remarkable structures that stand the test of time. With years of experience in the construction industry, we have earned a reputation for delivering high-quality projects that exceed expectations.
                </p>

                <p>
                    Our approach is centered on collaboration and customer satisfaction. We work closely with our clients to understand their vision and requirements, ensuring that each project is tailored to their unique needs. Our team employs the latest construction techniques and adheres to the highest industry standards to guarantee the successful delivery of every project.
                </p>

                <router-link to="/about" class="btn btn-1">Learn More</router-link>

            </div>

        </div>

    </div>

</section>


<section style="margin-top: 80px; background-color: rgba(0, 0, 0, 0.8); padding-bottom: 100px; background-image: url('/assets/projects/3bedroombungalow/WhatsApp Image 2023-07-28 at 11.47.40.jpeg'); background-size: cover; background-blend-mode: darken; background-attachment: fixed; ">

    <div class="container">

        <div style="text-align: center; padding-top: 100px; padding-bottom: 50px">
            <h6 style="color: #800;">Our Numbers</h6>
            <h1 style="color: #800;">Why Work With Us</h1>
        </div>

        <div class="row">

            <div class="col-md-4" style="padding-top: 20px;">
               <div style="text-align: center; color: #800; border: solid 2px #800; border-radius: 10px; padding: 20px; ">
                <h6> <i class="bi bi-grid"></i> </h6>
                <h3>10000+</h3>
                <h4>Projects Done</h4>
               </div>
            </div>

            <div class="col-md-4" style="padding-top: 20px;">
               <div style="text-align: center; color: #800; border: solid 2px #800; border-radius: 10px; padding: 20px; ">
                <h6> <i class="bi bi-people"></i> </h6>
                <h3>5000+</h3>
                <h4>Recommendations</h4>
               </div>
            </div>


            <div class="col-md-4" style="padding-top: 20px;">
               <div style="text-align: center; color: #800; border: solid 2px #800; border-radius: 10px; padding: 20px; ">
                <h6> <i class="bi bi-chat"></i> </h6>

                <h3>6000+</h3>
                <h4>Positive Reviews</h4>
               </div>
            </div>

        </div>

    </div>

</section>




<section style="padding-top: 80px;">

    <div class="container-fluid">

    

        <div class="row">

            <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
                <img src="/assets/projects/bypass/WhatsApp Image 2023-07-28 at 12.00.50.jpeg" style="width: 100%;" class="shadow-lg">
            </div>

            <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
                <img src="/assets/projects/2bedroomcottagesmachakos/WhatsApp Image 2023-07-28 at 12.10.34 (3).jpeg" style="width: 100%;" class="shadow-lg">
            </div>

            <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
                <img src="/assets/projects/3bedroombungalow/WhatsApp Image 2023-07-28 at 11.47.40 (1).jpeg" style="width: 100%;" class="shadow-lg">
            </div>

            <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
                <img src="/assets/projects/3bedroomestate/WhatsApp Image 2023-07-28 at 12.04.50 (5).jpeg" style="width: 100%;" class="shadow-lg">
            </div>

            <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
                <img src="/assets/projects/3bedroommansion/WhatsApp Image 2023-07-28 at 11.44.39 (1).jpeg" style="width: 100%;" class="shadow-lg">
            </div>

            <div class="col-md-3 col-6 gallery" style="padding-top: 20px;">
                <img src="/assets/projects/4bedroommansionkisumu/WhatsApp Image 2023-07-28 at 11.46.44.jpeg" style="width: 100%;" class="shadow-lg">
            </div>

            <div class="col-md-3 col-6 col-6 gallery" style="padding-top: 20px;">
                <img src="/assets/projects/5bedroommansion/WhatsApp Image 2023-07-28 at 11.41.54 (1).jpeg" style="width: 100%;" class="shadow-lg">
            </div>

            <div class="col-md-3 col-6 col-6 gallery" style="padding-top: 20px;">
                <img src="/assets/projects/5bedroommansion2/WhatsApp Image 2023-07-28 at 11.53.54.jpeg" style="width: 100%;" class="shadow-lg">
            </div>

        </div>

    </div>

</section>


<section>

    <div class="container" style="text-align: center; padding-top: 50px; background-color: #800; padding-bottom: 50px; margin-top: 100px; background-image: url('/assets/projects/5bedroommansion/WhatsApp Image 2023-07-28 at 11.41.54 (1).jpeg'); background-blend-mode: darken; ">

        <h2 style="color: lightgrey;">Have Any Questions?</h2>
        <router-link to="/contact" class="btn btn-2">Contact Us</router-link>

    </div>

</section>



<section style="background-color: rgba(0, 0, 0, 0.8); margin-top: 100px; background-image: url('/assets/projects/5bedroommansion/WhatsApp Image 2023-07-28 at 11.41.54 (1).jpeg'); background-blend-mode: darken; background-attachment: fixed; ">

<div class="container" style=" padding-top: 50px; padding-bottom: 100px;">

   <div style="padding-bottom: 30px;">
    <h2 style="color: lightgrey;">What Clients Say</h2>
   </div>
   
    <div class="row">

        <div class="col-md-4" style="padding-top: 20px;">

            <div style="background-color: #E1DEDE; padding: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; ">
                <h4 style="color: #800;"> <i class="bi bi-person-circle"></i> Mourice Okello</h4>
                <p>
                    We approached WNB Concept LTD for our office expansion project, and they truly exceeded our expectations. Their expertise in commercial construction was evident from the start. They not only delivered a functional and aesthetically pleasing workspace but also provided insightful suggestions that enhanced the overall design. The project was completed on time and within budget, and the communication throughout the process was exceptional. We are incredibly satisfied with their services and would choose WNB Concept LTD again for any future projects.
                </p>
            </div>

        </div>


        <div class="col-md-4" style="padding-top: 20px;">

<div style="background-color: #E1DEDE; padding: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; ">
    <h4 style="color: #800;"> <i class="bi bi-person-circle"></i> Sarah Johnson</h4>
    <p>
        We engaged WNB Concept LTD for a major renovation of our restaurant, and the transformation is simply stunning. Their team seamlessly integrated modern design elements while preserving the charm of the original structure. The project was managed efficiently, and any challenges that arose were addressed promptly. The attention to detail, quality craftsmanship, and commitment to delivering beyond our expectations make WNB Concept LTD stand out. They breathed new life into our space, and we couldn't be happier
    </p>
</div>

</div>


<div class="col-md-4" style="padding-top: 20px;">

<div style="background-color: #E1DEDE; padding: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 20px; ">
    <h4 style="color: #800;"> <i class="bi bi-person-circle"></i> James Kariuki</h4>
    <p>
        Our experience with WNB Concept LTD on our industrial facility was excellent from start to finish. Their in-depth knowledge of industrial construction regulations and processes was evident throughout the project. They provided innovative solutions that optimized the layout and functionality of the space. The dedication to safety, meticulous planning, and their ability to adapt to our evolving needs were commendable. <br/> WNB Concept LTD truly delivered a facility that aligns with our operational requirements.
    </p>
</div>

</div>

    </div>

</div>

</section>


<Footer />


</template>

<script>
import Header from './Header'
import Footer from './Footer'
export default{
    name : 'home',
    components : {Header,Footer},
    data () {
        return {
      
        }
    },
   
    created (){
        window.scrollTo(0,0)
    }
}
</script>
