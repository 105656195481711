<template>

  <div style="background-color: #800; text-align: center; color: #fff;">
    <p>Building with Precision and Passion.</p>
  </div>

<nav class="navbar header-design navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <router-link to="" class="navbar-brand"> <img src="/assets/images/logo-2.png" class="logo-design"> </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link" aria-current="page" >Home</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/about" class="nav-link" aria-current="page" >About Us</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/architectural" class="nav-link" aria-current="page" >Architectural</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/structural" class="nav-link" aria-current="page" >Structural</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/construction" class="nav-link" aria-current="page" >Construction</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/ongoing-projects" class="nav-link" aria-current="page" >Ongoing Projects <i class="bi bi-arrow-right"></i> </router-link>
        </li>
       
      </ul>
      <div class="d-flex">
        <router-link to="/contact" class="btn btn-1">Contact Us</router-link>
      </div>
    </div>
  </div>
</nav>

</template>